import { RouterType } from '../helpers/types'
import LoginPage from '../containers/Auth'
import {TestForCors} from "../components/TestForCors";
import {SighUpPage} from "../containers/Auth/SighUpPage";
import {LegalRequest} from "../containers/Client/LegalRequest/LegalRequest";
import {OfflineOffer} from "../containers/Offline/OfflineOffer";
import {TestRoute} from "../containers/TestRoute";
import {UpdateNps} from "../components/other/updatNps/UpdateNps";
import {NpsNoAuthRoute} from "../components/other/updatNps/NpsNoAuthRoute";
import {NpsAdmin} from "../components/other/updatNps/NpsAdmin";

const LoginRouter:RouterType[] = [
  {
    exact: true,
    path: '/',
    title: 'Войти',
    Component: LoginPage,
  },
  {
    exact: true,
    path: '/registration',
    title: 'Войти',
    Component: SighUpPage,
  },
  {
    exact: true,
    path: '/cors',
    title: 'Войти',
    Component: TestForCors,
  },
  {
    exact: false,
    path: '/offline-offer/:id',
    title: 'Offline Offer',
    Component: OfflineOffer,
  },
  {
    exact: false,
    path: '/test',
    title: 'Legal Request',
    Component: TestRoute,
  },
  {
    exact: false,
    path: '/update-nps/auth',
    title: 'Legal Request',
    Component: NpsNoAuthRoute,
  },
  {
    exact: false,
    path: '/update-nps/admin',
    title: 'Legal Request',
    Component: NpsAdmin,
  },
  {
    exact: false,
    path: '/update-nps',
    title: 'Legal Request',
    Component: NpsNoAuthRoute,
  },
]

export default LoginRouter
