import React, {useContext, useEffect, useState} from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import './settings.scss'
import userIcon from '../../assets/images/nav/user.svg'
import historyIcon from '../../assets/images/nav/history.svg'
import plansIcon from '../../assets/images/nav/plans.svg'
import paymentMethodIcon from '../../assets/images/nav/payment.svg'
import AccountSettings from './Routes/AccountSettings'
import PaymentHistory from './Routes/PaymentHistory'
import Subscriptions from './Routes/Subscriptions'
import PaymentMethods from './Routes/PaymentMethods'
import { MainContextProvider } from '../../context/MainContext'
import NotFound from "../NotFound/NotFound";
import {NavReport} from "../Layout/Layout/NavReport";
import {CardPaymentMethod} from "./Routes/Subscriptions/CardPaymentMethod";
import ProviderPaymentHistory from "./Routes/PaymentHistory/ProviderPaymentHistory";
import {PaymentPlan} from "./Routes/PaymentPlan/PaymentPlan";
import customAxios from "../../service/api/interceptors";
import {CryptoPaymentPage} from "./Routes/Checkout/CryptoPaymentPage/CryptoPaymentPage";

const Settings = () => {
  const { userData, setLoading, creditCount } = useContext(MainContextProvider)

  const role = userData?.status?.type

  return (
    <div className={'settings'}>
      {/*<h1>{'Settings'}</h1>*/}
      <div className={'settings__container'}>
        <div className={'settings__body'}>
          <Switch>
            <Route path={'/settings/account'} component={AccountSettings} />
            {userData?.status.type_code === 200 && <Route path={'/settings/history'} component={ProviderPaymentHistory} />}
            {userData?.status.type_code === 200 && <Route path={'/settings/crypto-payment/:id'} component={CryptoPaymentPage} />}
            {role === 'client' && <Route path={'/settings/history'} component={PaymentHistory} />}
            {role === 'client' ? <Route path={'/settings/subscriptions'} component={Subscriptions} /> : <NotFound />}
            {role === 'client' ? <Route path={'/settings/payment-methods'} component={PaymentMethods} /> : <NotFound />}
            {role === 'client' ? <Route path={'/settings/stripe-payment-method/:id'} component={CardPaymentMethod} /> : <NotFound />}
            {role === 'client' ? <Route path={'/settings/payment-plan'} component={PaymentPlan} /> : <NotFound />}
            {role === 'client' ? <Route path={'/settings/crypto-payment/:id'} component={CryptoPaymentPage} /> : <div>123231</div>}
          </Switch>
        </div>
        <div className={'settings__nav'}>
          <NavLink to={'/settings/account'} className={'settings__nav-item'}>
            <img src={userIcon} alt={''} />
            <span>{'Account Information'}</span>
          </NavLink>
          {/* {role === "client" && <NavLink to='/settings/payment-methods' className="settings__nav-item">
                        <img src={paymentMethodIcon} alt=""/>
                        <span>Payment Method</span>
                    </NavLink>} */}
          {/*{role === 'client' && userData?.flows.onboarding === 1 && (
            <NavLink to={'/settings/subscriptions'} className={'settings__nav-item'}>
              <img src={plansIcon} alt={''} />
              <span>{'Your Monthly Plans'}</span>
            </NavLink>
          )}*/}
          {role === 'client' && userData?.flows.onboarding === 1 && (
              <NavLink to={creditCount.isCreditSubscription ? '/settings/payment-plan' : '/settings/subscriptions'} className={'settings__nav-item'}>
                <img src={plansIcon} alt={''} />
                <span>{creditCount.isCreditSubscription ? 'Manage My plan & Сredits' : 'Your Monthly Plans'}</span>
              </NavLink>
          )}
          {((role === "client" && userData?.flows.onboarding === 1) || userData?.status.type_code === 200) &&
            <NavLink to='/settings/history' className="settings__nav-item">
                <img src={historyIcon} alt=""/>
                <span>Billing history</span>
            </NavLink>
          }
        </div>
      </div>
    </div>
  )
}

export default Settings