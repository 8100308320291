import React from "react";
import logoIcon from "../../../assets/images/logo.svg";
import ModalContainer from "../../../components/ModalContainer";

type Props = {
    isOpen: boolean
    close: () => void
    title: string
    subtitle: string
    description: string
}

export const NoCreditsModal: React.FC<Props> = ({
    isOpen, close, title, subtitle, description
}) => {
    return (
        <ModalContainer isOpen={isOpen} close={close} heading={''}>
            <>
                <div className={'flex justifyCenter'}>
                    <img src={logoIcon} alt=""/>
                </div>
                <div className="heading4 text-center mt40">
                    {title}
                </div>
                <p className={'p1 color-blue text-uppercase text-center mt20'}>
                    {subtitle}
                </p>
                <p className={'p1 text-center mt20'}>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </p>
                <div className={'mt20'}>
                    <div className={'planner-page__modal-container'}>
                        Credits Balance: <b className={'text-weight-600'} style={{ color: '#FF0000' }}>{'0'}</b>
                    </div>
                </div>
                <div>
                    <button onClick={() => window.location.href = '/settings/payment-plan'} className={'btn btn-black w100 text-uppercase mt40'}>
                        BUY cREDITS
                    </button>
                    <button onClick={close} className={'btn-white w100 text-uppercase mt10'}>
                        GO BACK
                    </button>
                </div>
            </>
        </ModalContainer>
    )
}