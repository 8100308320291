import React, {useContext, useEffect, useRef, useState} from "react";
import './CreateLegalRequest.scss'
import {createLegalRequestService, createNeedService, needListService} from "../../../service/client/legalRequest";
import toBottomIcon from '../../../assets/images/bottom.png'
import {CSSTransition} from "react-transition-group";
import {Input, ReactEditor, ReactSelect, TextArea} from "../../../components/UI";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {toastifyError} from "../../../service/toastify/toastify";
import {useHistory} from "react-router-dom";
import {MainContextProvider} from "../../../context/MainContext";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import customAxios from "../../../service/api/interceptors";
import {useIntercom} from "react-use-intercom";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import {CompanyType} from "../../../types/company.type";
import {CreditControlModal} from "../Credits/CreditControlModal";

type NeedsItemType = {
    id: string,
    need: string
}

type CategoryItemType = {
    id: string
    title: string
    subcategories: {
        id: number
        title: string,
        category_id: number
    }[]
}

type NeedsDataType = {
    [key: string]: { items: NeedsItemType[] }
}

const isShowCreateLegalRequestButton = (clients: CompanyType[], client_id: string) => {
    const client_object: any = clients.find((el) => +el.id === +client_id)
    return client_object?.debt !== "unsubscribed";
}

export const CreateLegalRequest: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [needs, setNeeds] = useState<NeedsDataType>({} as NeedsDataType)
    const [choose, setChoose] = useState('')
    const [chooseTitle, setChooseTitle] = useState('')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [companies, setCompanies] = useState<any[]>([])
    const [categoryId, setCategoryId] = useState<null | string>(null)
    const [categories, setCategories] = useState<CategoryItemType[]>([])
    const [values, setValues] = useState({title: '', describe: '', company_id: ''})
    const [errors, setErrors] = useState<{ [key: string]: string }>({})
    const [haveToBeApproveModal, setHaveToBeApproveModal] = useState(false)

    const { setModalType, userData, getCreditCount } = useContext(MainContextProvider)
    const history = useHistory()
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsDropdownOpen(false) })
    const { showNewMessages } = useIntercom()

    const getNeedList = async () => {
        setLoading(true)
        try {
            const { status: categoryStatus, data: categoryData } = await customAxios.get(`/company/project/category`)

            const customEl = categoryData.find((el: CategoryItemType) => (el.title || "").toLocaleLowerCase().trim() == "custom request")
            setCategoryId(customEl.id)

            setCategories(categoryData.map((el: CategoryItemType) => {
                if ((el.title || "").toLocaleLowerCase().trim() == "custom request") {
                    return {
                        id: el.id,
                        title: 'Custom Request',
                        subcategories: [
                            { id: 0, title: 'Enter Custom Request', category_id: el.id }
                        ]
                    }
                } else {
                    return el
                }
            }))
            const { data: clientsData } = await customAxios.get(`/company/company/get-companies`)
            setCompanies(clientsData)
            setValues({
                ...values,
                company_id: clientsData[0].id
            })

        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }

        setLoading(false)
    }

    const createLegalRequest = async (id?: string) => {
        if (chooseTitle.trim().length === 0) {
            setErrors({ title: "Field is required" })
        } else {
            setErrors({})
        }

        setLoading(true)
        try {
            const { status, data } = userData?.status.type_code === 100 ?
                await customAxios.post(`/company/client/kanban/create-custom-legal-request`, {
                    category_id: categoryId, company_id: values.company_id, title: chooseTitle
                }) :
                await customAxios.post(`/company/manager/kanban/create-custom-legal-request`,{
                    category_id: categoryId, company_id: values.company_id, title: chooseTitle
                })
            if((status === 200 || status === 201) && data) {
                setModalType('')
                history.push(`/legal-request/${data.id}`)
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }
    useEffect(() => {
        getNeedList().then()
        if(userData?.status.type_code === 100) getCreditCount()
    }, [])

    const chooseItem = (id: number, need: string, category_id: number) => {
        setChoose(id + '')
        setIsDropdownOpen(false)
        setChooseTitle(need)
        setCategoryId(category_id + '')
    }

    const chaster_left = 2001 - +(values.describe.replace(/&nbsp;/gi, '').trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').trim().length < 1 || (values.describe.trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')).length || 0)

    return (
        <>
            {haveToBeApproveModal && <CreditControlModal
                submit={createLegalRequest}
                close={() => setHaveToBeApproveModal(false)}
                creditsSum={1}
                commonTitle={values.title}
                noCreditsSubtitle={'INSUFFICIENT CREDITs'}
                noCreditsDescription={`This request requires <b>1 Credit</b>. You do not have enough Credits to proceed. This request requires 1 Credit. You do not have enough Credits to proceed. You can purchase more Credits now and then go to your Legal Roadmap to submit this request.`}
            />}
            {
                <div className={'create_legal_request__body'}>
                    {loading && <ModalLoader />}
                    <div className="create_legal_request__description">
                        Choose from the list of options for your new request, or create a custom request
                    </div>

                    <ReactSelect
                        className={'create_manager_offer__react-select'}
                        value={values.company_id}
                        options={companies.map(item => ({ value: item.id, label: item.name }))}
                        onChange={e => setValues({ ...values, company_id: e.value })}
                        valid={!errors?.client_id}
                        errorMessage={errors.client_id}
                    />
                    <div className="create_legal_request__select" ref={dropdownRef}>
                        <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="create_legal_request__select-container">
                            <span>{chooseTitle || 'Choose option'}</span>
                            <img src={toBottomIcon} alt=""/>
                        </div>
                        <CSSTransition in={isDropdownOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                            <div className="create_legal_request__dropdown">
                                {
                                    (categories || []).map(({ id, title, subcategories }) => (
                                        <div key={id} className={'create_legal_request__need-block'}>
                                            <b>{title}</b>
                                            <div className={'create_legal_request__need-items'}>
                                                {
                                                    subcategories.map(({ title, id, category_id }) => (
                                                        <div
                                                            key={id}
                                                            onClick={() => chooseItem(id, title, category_id)}
                                                            className={'create_legal_request__need-item'}>
                                                            <div className="create_legal_request__radio">
                                                                {
                                                                    +choose === +id && <div className="create_legal_request__radio-active" />
                                                                }
                                                            </div>
                                                            <span>{title}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </CSSTransition>
                    </div>

                    <div className={'create_legal_request__custom-request'}>
                        {categoryId && <div className="create_legal_request__custom-request-heading">
                            OR CREATE CUSTOM REQUEST
                        </div>}
                        {categoryId && <Input
                            className={'create_legal_request__custom-request-input'}
                            otherProps={{
                                value: chooseTitle,
                                onChange: (e) =>
                                    setChooseTitle(e.target.value),
                                placeholder: 'Title',
                                // disabled: !!choose
                            }}
                            valid={!errors.title} errorMessage={errors?.title}
                        />}
                        {categoryId && <div style={{ marginTop: '10px' }}>
                            <ReactEditor
                                value={values.describe}
                                onChange={text => setValues({ ...values, describe: text })}
                                valid={!errors.describe} errorMessage={errors?.describe}
                            />
                            <em style={{ fontSize: '12px', color: '#9b9b9b', paddingLeft: '5px' }}>
                                {chaster_left > 0 ? chaster_left : 0} characters left
                            </em>
                        </div>}
                        {
                            <button
                                title={!isShowCreateLegalRequestButton(companies, values.company_id) ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                                disabled={!isShowCreateLegalRequestButton(companies, values.company_id)}
                                onClick={() => {
                                    if (chooseTitle.trim().length === 0) {
                                        setErrors({ title: "Field is required" })
                                        return
                                    } else {
                                        setErrors({})
                                    }
                                    setHaveToBeApproveModal(true)
                                }}
                                className={'btn-black create_legal_request__custom-request-button'}>
                                SUBMIT
                            </button>
                        }
                    </div>
                </div>
            }
        </>
    )
}