import {useContext, useEffect, useState} from "react";
import logoImg from "../../../assets/images/logo.svg"
import closeImg from "../../../assets/images/modal-close.svg"
import arrowImg from "../../../assets/images/right-white-arrow.svg"
import {MainContextProvider} from "../../../context/MainContext";
import "./choose_payment_plan.scss"
import customAxios from "../../../service/api/interceptors";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {useIntercom} from "react-use-intercom";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import {useHistory} from "react-router-dom";
import {toastifySuccess} from "../../../service/toastify/toastify";

type PlanItemType = {
    id: number
    payment_plan_option: number
    title: string
    sale_text: string | null
    price_monthly: number
    price: number
}

export const ChoosePaymentPlan = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const { setModalType, setModalData } = useContext(MainContextProvider)
    const [items, setItems] = useState<PlanItemType[]>([])
    const { showNewMessages } = useIntercom()


    const getPlans = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/v1/user/client/invoice/payment-plans`)
            if (status === 200) {
                setItems(Object.keys(data).map(key => ({ ...data[key] })) || [])
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const choosePlan = async (payment_plan_option: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/v1/user/client/invoice/create-retainer`, {
                subscription_id: payment_plan_option
            })
            if (status === 200) {
                setModalType('')
                history.push(`/settings/history`)
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(true)
    }

    useEffect(() => {
        getPlans().then()
    }, [])

    return (
        <div className={'choose_payment_plan__container'} style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <div className="choose_payment_plan__header">
                <img src={logoImg} alt=""/>
                <img onClick={() => setModalType('')} src={closeImg} className="choose_payment_plan__close" alt="" />
            </div>
            <div className="choose_payment_plan__body">
                <p className={'choose_payment_plan__desc'}>
                    Choose your payment plan
                </p>
                <div className="choose_payment_plan__plans">
                    {
                        items.map((item) => (
                            <a
                                onClick={(window.innerWidth < 500) ? () => choosePlan(item.id) : () => {}}
                                style={{ display: 'block', textDecoration: 'none' }} className={`choose_payment_plan__plan-container ${item.sale_text && 'choose_payment_plan__sale-text'}`}>
                                <div className={'choose_payment_plan__plan'}>
                                    <div className="choose_payment_plan__plan__left">
                                        {item.title}
                                    </div>
                                    <div className="choose_payment_plan__plan__right">
                                        <div className="choose_payment_plan__plan__price">
                                            <b>{item.price_monthly || item.price}</b>
                                            <em>/MTH</em>
                                        </div>
                                        <button
                                            onClick={(window.innerWidth >= 500) ? () => choosePlan(item.id) : () => {}}
                                            className={'btn-black'}>
                                            <span>select</span>
                                        </button>
                                    </div>
                                </div>
                                <button
                                    onClick={(window.innerWidth >= 500) ? () => choosePlan(item.id) : () => {}}
                                    className={'btn-black choose_payment_plan__mobile-button'}>
                                    <img src={arrowImg} alt=""/>
                                </button>
                                {/*{item.sale_text && <div className="choose_payment_plan__plan-sale">
                                    {item.sale_text}
                                </div>}*/}
                            </a>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}