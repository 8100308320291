import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ClientKanbanHeader} from "../../../components/other/ClientKanbanBoard/ClientKanbanHeader/ClientKanbanHeader";
import TasksProjectKanbanGrid from "../../Lawyer/Task/TasksProjectKanban/TasksProjectKanbanGrid";
import {MainContextProvider} from "../../../context/MainContext";
import {clientKanbanService} from "../../../service/client/kanban";
import customAxios from "../../../service/api/interceptors";
import {getManagerKanbanService} from "../../../service/manager/legalBoard";
import {KanbanProjectTabs} from "../../../components/other/KanbanProjectTabs/KanbanProjectTabs";
import {KanbanTabs} from "./KanbanTabs";
import {useHistory, useLocation} from "react-router-dom";
import {SortKanbanBoard} from "./SortKanbanBoard/SortKanbanBoard";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    ClientsSearchDropdown,
    getCompanyStatus
} from "../../../components/other/ClientsSearchDropdown/ClientsSearchDropdown";
import {CompanyType} from "../../../types/company.type";
import moment from "moment";
import {FrozenController} from "../../../components/other/FrozenController/FrozenController";

type clientSubscriptionType = {
    title: string
    activate_date: string
    active: string
}

function processTable(data: any) {
    const obj: any = {}
    let i = 0;

    Object.keys(data).map(key => {
        obj[key] = data[key].offers.map((el: any) => {
            i++
            return { ...el, index: i }
        })
    })

    return obj
}

function getPerPage() {
    return 6
}

export const ManagerKanban: React.FC = () => {
    const { userData } = useContext(MainContextProvider)
    const { search } = useLocation()
    const history = useHistory()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { client } = Object.fromEntries(urlSearchParams.entries()) || {  };
    const location = useLocation()

    const { setModalType, setLoading } = useContext(MainContextProvider)
    const [kanbanTasksCount, setKanbanTasksCount] = useState({
        "business need to discuss": { count: 0 },
        "offers (pending)": { count: 0 },
        "tasks assigned": { count: 0 },
        "tasks done": { count: 0 }
    })
    const [kanbanTasks, setKanbanTasks] = useState({
        "business need to discuss": [],
        "offers (pending)": [],
        "tasks assigned": [],
        "tasks done": []
    })
    const [manager, setManager] = useState(null)
    const [managers, setManagers] = useState(null)
    const [kanbanStatuses, setKanbanStatuses] = useState<any>({})
    const [clientSubscription, setClientSubscription] = useState<clientSubscriptionType>({} as clientSubscriptionType)
    const [clients, setClients] = useState<CompanyType[]>([])
    const searchParams = new URLSearchParams(location.search);
    const fetchMoreDataRef = useRef({});
    const totalCountRef = useRef(0);
    const [fetchItems, setFetchItems] = useState([{ item: 'a' }])
    // const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
    // const dropdownRef = useRef<HTMLDivElement>(null)
    // useOnClickOutside({ ref: dropdownRef, handler: () => setIsMobileFilterOpen(false) })

    const getClientSubscriptionData = async () => {
        const { status, data } = await customAxios.get(`/company/${searchParams.get('client') || clients[0].id}/subscription`)
        if (status === 200) {
            setClientSubscription(data)
        }
    }

    useEffect(() => {
        if (clients.length === 0) {
            return
        }
        getClientSubscriptionData().then()
    }, [clients, searchParams.get('client')])

    const updateParam = useCallback((paramName, newValue) => {
        if (paramName !== 'page') {
            searchParams.set('page', '1')
        }
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${location.pathname}?${searchParams.toString()}`);
    }, [history, location.pathname, location.search]);

    const getTimeoutKanbanData = async () => {
        const order_by = localStorage.getItem('manager-kanban-sort-value') || '1'
        const per_page = searchParams.get('page') ? getPerPage() * +(searchParams.get('page') || 1) : getPerPage()

        setLoading(true)
        const { data, status } = await getManagerKanbanService(+client, order_by, userData?.status.type_code, per_page)
        if(status === 200 && data) {
            setFetchItems([...fetchItems, ...fetchItems])
            setKanbanTasksCount(data.kanban)
            fetchMoreDataRef.current = processTable(data.kanban)

            let moreBiggerCount = 0
            Object.keys(data.kanban).map(key => {
                if (data.kanban[key].count > moreBiggerCount) {
                    moreBiggerCount = data.kanban[key].count
                }
            })
            totalCountRef.current = moreBiggerCount

            setKanbanTasks(processTable(data.kanban))
            setKanbanStatuses(data['kanban-statuses'])
            setLoading(false)
        }
    }

    const getClientForManager = async () => {
        const { data: clientsData } = await customAxios.get(`/company/company/get-companies?expand=client,company&per-page=500`)
        setClients([
            ...clientsData.filter((el: CompanyType) => el.debt !== 'unsubscribed'),
            ...clientsData.filter((el: CompanyType) => el.debt === 'unsubscribed'),
        ])
        if(client) {
            return;
        }
        // history.push(`/dashboard?client=${clientsData[0].id}`)
    }

    const fetchMoreData = async () => {
        if (+(searchParams.get('page') || 1) >= Math.ceil(Number(totalCountRef.current) / Number(getPerPage()))) {
            return
        }

        setLoading(true)
        try {
            const order_by = localStorage.getItem('manager-kanban-sort-value') || '1'
            const { data } = await getManagerKanbanService(+client, order_by, userData?.status.type_code, getPerPage(), +(searchParams.get('page') || 1) + 1)
            const parseKanban = JSON.parse(JSON.stringify(fetchMoreDataRef.current))
            setFetchItems([...fetchItems, ...fetchItems])
            Object.keys(data.kanban).map(key => {
                parseKanban[key] = [...parseKanban[key], ...data.kanban[key].offers]
            })
            fetchMoreDataRef.current = parseKanban
            setKanbanTasks(parseKanban)
            updateParam('page', +(searchParams.get('page') || '1') + 1)
        } catch (e) {}

        setLoading(false)
    }

    useEffect(() => {
        document.title = 'Your Dashboard'
        getClientForManager().then()
        setLoading(true)
    }, [])

    useEffect(() => {
        if (clients.length > 0) {
            getTimeoutKanbanData().then()
        }
    }, [clients])

    useEffect(() => {
        if (!searchParams.get('page')) {
            getTimeoutKanbanData().then()
        }
    }, [searchParams.get('page'), searchParams.get('client')])

    return (
        <div className={'tasks-project-kanban'}>
            {searchParams.get('client') && <FrozenController debt={clients.find(el => String(el.id) === searchParams.get('client'))?.debt || 'none'}/>}
            <div style={{ paddingBottom: '20px' }}>
                {clients.length > 0 && <div className={'w100'} style={{ background: '#FFF', padding: '10px', borderRadius: '10px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className={'flex alignCenter'}>
                        <div style={{ maxWidth: '350px' }}>
                            <ClientsSearchDropdown
                                id={'kanban-client-dropdown'}
                                onClick={(key) => {
                                    window.location.href = `/dashboard?client=${key}`
                                    //history.push(`/dashboard?client=${key}`)
                                }}
                                value={searchParams.get('client') || ''}
                                arr={[
                                    {value:'', label: 'All'},
                                    ...clients.map((el: CompanyType) => ({ ...el, value: String(el.id), label: el.name}))
                                ]}
                            />
                        </div>
                        {
                            (searchParams.get('client')) && userData?.status.type_code !== 100 && <div
                                style={{
                                    color: getCompanyStatus(clients.find(el => String(el.id) === (searchParams.get('client') || String(clients[0].id)))?.debt || '').color,
                                    background: getCompanyStatus(clients.find(el => String(el.id) === (searchParams.get('client') || String(clients[0].id)))?.debt || '').background,
                                    borderRadius: '20px',
                                    padding: '8px 18px',
                                    marginLeft: '15px',
                                    fontSize: '13px',
                                    textTransform: 'uppercase'
                                }}>{getCompanyStatus(clients.find(el => String(el.id) === (searchParams.get('client') || String(clients[0].id)))?.debt || '').label}</div>
                        }
                        {searchParams.get('client') && clientSubscription?.activate_date && userData?.status.type_code !== 100 && <div>
                                <span style={{
                                    fontSize: '12px',
                                    fontFamily: 'Inter',
                                    marginLeft: '15px'
                                }}>{clientSubscription?.title}</span>
                            <em style={{fontSize: '12px', fontFamily: 'Inter', marginLeft: '15px'}}>Start
                                on: {moment(new Date(+clientSubscription?.activate_date * 1000)).format('DD-MM-YYYY')}</em>
                        </div>}

                    </div>
                    <SortKanbanBoard id={'kanban-sort-dropdown'} onClick={(value) => window.location.reload()} />
                </div>}
                {/*<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                        <KanbanTabs dropdownOptions={[{id:'', name: 'All'}, ...clients]} />
                    </div>
                    <ClientKanbanHeader manager={manager} managers={managers} />
                    {(userData?.status.type_code === 300 || userData?.status.type_code === 400) &&
                        <SortKanbanBoard onClick={(value) => window.location.reload()} />}
                </div>*/}
            </div>
            {/*<KanbanProjectTabs
                filterConfig={filterConfig}
                onChange={() => {}}
                value={''}
            />*/}
            <InfiniteScroll
                dataLength={fetchItems.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<></>}
            >
                <div className={'tasks-project-kanban__body'}>
                    {
                        <TasksProjectKanbanGrid
                            kanbanTasksCount={kanbanTasksCount}
                            kanbanTasks={kanbanTasks}
                            kanbanStatuses={kanbanStatuses}
                            modalKey={'create_manager_offer'}
                            isCreateLegalRequestButton={userData?.status.type_code === 300}
                            updateBoard={() => {
                                getTimeoutKanbanData().then()
                            }}
                        />
                    }
                </div>
            </InfiniteScroll>

            {/*<div onClick={() => setModalType('create_task')} className={'mobile-crate-task-icon'}><img src={plusIcon} alt=""/></div>*/}
            {/*<div>
            {window.innerWidth <= 460 && <Header icons={() => <div ref={dropdownRef}>
                <a onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)} className="header__right-link"><img src={searchIcon} alt=""/></a>
                <CSSTransition in={isMobileFilterOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                    <div className={'tasks-project-kanban__header'}>
                        <TasksProjectKanbanHeader
                            selectedFilters={filters}
                            filterConfig={filterConfig}
                        />
                    </div>
                </CSSTransition>
            </div>} />}
            <KanbanProjectTabs
                filterConfig={filterConfig}
                onChange={() => {}}
                value={''}
            />
            {window.innerWidth > 460 && <div className={'tasks-project-kanban__header'}>
                <TasksProjectKanbanHeader
                    selectedFilters={filters}
                    filterConfig={filterConfig}
                />
            </div>}
            <SubscriptionProgressLine initialProjectId={subscriptionOptionId} />
        </div>*/}
        </div>
    )
}